import React from "react";
import { useState, useContext } from "react";
import styles from "../auth.module.css";

import { AuthContext } from "../../../context/AuthContext";

import CheckboxField from "../../GlobalComponents/CheckboxField";
import SignUpComponentSidebar from "../SignUpComponentSidebar";
import CustomDropdown from "../CustomDropdown";
import DownArrowIcon from "../../GlobalComponents/DownArrowIcon";
import ConfirmButton from "../../GlobalComponents/ConfirmButton";

const PlatformInformationTwo = ({
  onNext,
  onBack,
  handleInputChange,
  formData,
  color,
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const [error, setError] = useState(null);

  // let canContinue =
  //   formData.gender &&
  //   formData.birthday &&
  //   formData.firmName &&
  //   formData.aboutMe &&
  //   formData.isFinancialAdvisor === true;

  let canContinue = formData.reason_for_use && formData.hear_about;

  async function handleCreateAccount() {
    onNext();
  }

  async function handleBackClick() {
    onBack();
  }

  const toggleDropdown = () => setIsDropdownVisible(!isDropdownVisible);

  return (
    <>
      <div className={styles.authentication_input_side}>
        <div className={styles.authentication_input_layout}>
          <div className={styles.input_options}>
            <p className={styles.page_title}>Create Your Nexus App</p>
            <p className={styles.page_subtitle}>Tell Us About Your Platform</p>

            <p className={styles.login_input_titles}>
              What are you using your Nexus App for?
            </p>
            <div className={styles.paragraph_input}>
              <textarea
                onChange={(event) =>
                  handleInputChange("reason_for_use", event.target.value)
                }
                value={formData.reason_for_use}
                className={styles.textarea_input}
              ></textarea>
            </div>

            <p className={styles.login_input_titles}>
              How did you hear about Nexus?
            </p>
            <div className={styles.email_input}>
              <input
                onChange={(event) =>
                  handleInputChange("hear_about", event.target.value)
                }
                value={formData.hear_about}
                className={styles.input}
              ></input>
            </div>
          </div>

          <>
            <ConfirmButton
              color={canContinue ? color : undefined}
              text="Continue"
              action={handleCreateAccount}
              canContinue={canContinue}
              // isLoading={isLoading}
            />
            <p className={styles.forgot_password} onClick={onBack}>
              Previous Step
            </p>
          </>
        </div>
      </div>
    </>
  );
};

export default PlatformInformationTwo;
