import ColorPicker from "react-best-gradient-color-picker";
import React from "react";
import { useState, useContext, useEffect } from "react";
import styles from "../auth.module.css";

import { AuthContext } from "../../../context/AuthContext";

import ConfirmButton from "../../GlobalComponents/ConfirmButton";

const ColorScheme = ({
  onNext,
  onBack,
  handleInputChange,
  formData,
  color,
  setColor,
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const [error, setError] = useState(null);

  // let canContinue =
  //   formData.gender &&
  //   formData.birthday &&
  //   formData.firmName &&
  //   formData.aboutMe &&
  //   formData.isFinancialAdvisor === true;

  let canContinue = true;

  async function handleCreateAccount() {
    onNext();
  }

  async function handleBackClick() {
    onBack();
  }

  const toggleDropdown = () => setIsDropdownVisible(!isDropdownVisible);
  const [pickerWidth, setPickerWidth] = useState(window.innerWidth / 3);
  // Update the picker width on window resize
  useEffect(() => {
    const handleResize = () => {
      setPickerWidth(window.innerWidth / 3);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures this effect runs only once at mount

  return (
    <>
      <div className={styles.authentication_input_side}>
        <div className={styles.authentication_input_layout}>
          <div className={styles.input_options}>
            <p className={styles.page_title}>Create Your Nexus App</p>
            <p className={styles.page_subtitle}>Select Your Brand Color</p>
            <div className={styles.color_picker_layout}>
              <div className={styles.color_picker}>
                <ColorPicker
                  value={color}
                  // width={600}
                  // style={{ width: '100%' }}
                  width={pickerWidth}
                  onChange={setColor}
                  hideOpacity={true}
                  hidePresets={true}
                />
              </div>
            </div>
          </div>
          <>
            <ConfirmButton
              color={canContinue ? color : undefined}
              text="Continue"
              action={handleCreateAccount}
              canContinue={canContinue}
              // isLoading={isLoading}
            />
            <p className={styles.forgot_password} onClick={onBack}>
              Previous Step
            </p>
          </>
        </div>
      </div>
    </>
  );
};

export default ColorScheme;
