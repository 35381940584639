import React, { useState } from "react";
import CircularLoader from "../CircularLoader/CircularLoader";

const ConfirmButton = ({ color, text, action, canContinue, isLoading }) => {
  const [isHovered, setIsHovered] = useState(false);

  const changeColorOpacity = (color, newOpacity) => {
    // Regular expression to match the rgba color format
    const regex = /rgba\((\d{1,3}), (\d{1,3}), (\d{1,3}), (\d(\.\d+)?)\)/;
    const match = color.match(regex);
    if (match) {
      // Reconstruct the color with the new opacity
      return `rgba(${match[1]}, ${match[2]}, ${match[3]}, ${newOpacity})`;
    }
    return color; // Return the original color if it doesn't match the expected format
  };
  const baseStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "50px",
    backgroundColor: canContinue
      ? color || "rgba(243, 201, 60, 1)"
      : "rgba(243, 201, 60, 0.5)",
    border: `1px solid ${canContinue ? "rgba(243, 201, 60, 0)" : color}`,
    color: "white",
    marginTop: "20px",
    marginBottom: "10px",
    cursor: canContinue ? "pointer" : "not-allowed",
    opacity: canContinue ? 1 : 0.5,
  };

  // Adjust style based on hover state
  const hoverStyle = {
    ...baseStyle,
    backgroundColor: canContinue
      ? changeColorOpacity(color, 0.4) || "rgba(243, 201, 60, 0.4)"
      : baseStyle.backgroundColor,
    border: `1px solid ${color}`,
    color: color,
  };

  const currentStyle =
    isHovered && canContinue && !isLoading ? hoverStyle : baseStyle;

  return (
    <div
      style={currentStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => canContinue && !isLoading && action()}
    >
      {!isLoading ? <p>{text}</p> : <CircularLoader />}
    </div>
  );
};

export default ConfirmButton;
