import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyByxhSTqK3rSkAB0gc4BQ9SbJKlUhQCtyY",
  authDomain: "nexus-84ef5.firebaseapp.com",
  projectId: "nexus-84ef5",
  storageBucket: "nexus-84ef5.appspot.com",
  messagingSenderId: "1048659590265",
  appId: "1:1048659590265:web:e7990c6d0ce5ed995868a8",
  measurementId: "G-CRSHEPKVE8",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { app, auth, db, storage };
