import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useContext } from "react";
import App from "./App";

import SignInPage from "./pages/SignInPage";
import SignUpPage from "./pages/SignUpPage";

// import PrivacyPolicyPage from "./pages/Landing/PrivacyPolicyPage";
// import TermsOfService from "./pages/Landing/TermsOfService";

import { AuthContext } from "./context/AuthContext";

// function PrivateRoute({ children }) {
//   const { currentUser } = useContext(AuthContext);

//   const isUserAuthenticated = localStorage.getItem("authUser") || currentUser;

//   return isUserAuthenticated ? children : <Navigate to="/signin" />;
// }

function routes() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App childComp={<SignUpPage />} />} />

          {/* <Route
            path="/privacy-policy"
            element={<App childComp={<PrivacyPolicyPage />} />}
          />
          <Route
            path="/terms-of-service"
            element={<App childComp={<TermsOfService />} />}
          /> */}

          <Route path="/signup" element={<App childComp={<SignUpPage />} />} />
          <Route
            path="/signup/step/:stepNum"
            element={<App childComp={<SignUpPage />} />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default routes;
