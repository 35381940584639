import React from "react";
import { useState, useContext, useEffect } from "react";

import styles from "../auth.module.css";

import SignUpComponentSidebar from "../SignUpComponentSidebar";
import { useStripe, useElements } from "@stripe/react-stripe-js";

import { Elements, PaymentElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CircularLoader from "../../CircularLoader/CircularLoader";
import CheckoutForm from "../CheckoutForm";

const stripePromise = loadStripe(
  "pk_test_51NHy0PKcb2S7BJkwy88Kh66Hb77wx9oXbW5zUFR9dReP52iviqwn6wIQGhZqDLNbjhwmNg19Um55IXGTDSlPh38b001wfYl7cb"
);

const PaymentInformation = ({ onNext, onBack, formData }) => {
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    const DEVELOPMENT_API_KEY = "RA-q7L5m2P3nT8vY4dH0rJ6cX1zG9wA3fU";
    const customerId = formData.stripe_id;
    const priceId = formData?.subscription_type?.id;

    fetch(
      "https://api.development.replyassist.com/payment/create-subscription",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${DEVELOPMENT_API_KEY}`,
        },
        body: JSON.stringify({
          customerId: customerId,
          priceId: priceId,
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setClientSecret(data.clientSecret);
      })
      .catch((error) => {
        console.error("Error:", error);
        setError("Failed to create subscription.");
      });
  }, [formData]);

  const appearance = { theme: "stripe" };
  const options = clientSecret ? { clientSecret, appearance } : { appearance };

  const [error, setError] = useState(null);

  const canContinue = true;

  async function handleCreateAccount() {
    onNext();
  }

  async function handleBackClick() {
    onBack();
  }

  return (
    <>
      <div className={styles.authentication_input_side}>
        <div className={styles.authentication_input_layout}>
          <div className={styles.input_options}>
            <p className={styles.page_title}>Payment Details</p>
            <p className={styles.page_subtitle}>Enter Payment Information</p>

            {!clientSecret ? (
              <div className={styles.loader_layout}>
                <CircularLoader color="rgba(0, 48, 140, 1)" size="10%" />
              </div>
            ) : (
              <div className={styles.checkout_form}>
                <Elements stripe={stripePromise} options={options}>
                  <CheckoutForm
                    clientSecret={clientSecret}
                    formData={formData}
                    onNext={onNext}
                  />
                </Elements>
              </div>
            )}
          </div>

          <>
            <p className={styles.forgot_password} onClick={handleBackClick}>
              Previous Step
            </p>
          </>
        </div>
      </div>
    </>
  );
};

export default PaymentInformation;
