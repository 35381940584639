import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";

import styles from "./auth.module.css";

import GeneralSignUp from "./SignUpPages/GeneralSignUp";
import GeneralInformation from "./SignUpPages/GeneralInformation";
import ChooseSubscription from "./SignUpPages/ChooseSubscription";
import PaymentInformation from "./SignUpPages/PaymentInformation";
import LocationInformation from "./SignUpPages/LocationInformation";
import PlatformInformation from "./SignUpPages/PlatformInformation";
import ColorScheme from "./SignUpPages/ColorScheme";
import LogoInformation from "./SignUpPages/LogoInformation";
import colorAlphaConverter from "../../utils/colorAlphaConverter";
import PlatformInformationTwo from "./SignUpPages/PlatformInformationTwo";
import UploadProfilePicture from "./SignUpPages/UploadProfilePicture";

import SignUpComponentSidebar from "./SignUpComponentSidebar";
import { AuthContext } from "../../context/AuthContext";
const company_stages = [
  // { component: GeneralSignUp, stage: 1 },
  { component: PlatformInformation, stage: 1 },
  { component: PlatformInformationTwo, stage: 2 },
  { component: LogoInformation, stage: 3 },
  { component: ColorScheme, stage: 4 },
  { component: GeneralSignUp, stage: 5 },
  { component: UploadProfilePicture, stage: 6 },
  { component: GeneralInformation, stage: 7 },
  // { component: LocationInformation, stage: 5 },
  // { component: ColorScheme, stage: 6 },
  // { component: ChooseSubscription, stage: 4 },
  // { component: PaymentInformation, stage: 5 },
];

const SignUpComponent = ({ step }) => {
  const [userId, setUserId] = useState(null);
  const [currentStage, setCurrentStage] = useState(1);
  const [formData, setFormData] = useState({});
  const [isCreatingApp, setIsCreatingApp] = useState(false);

  const [color, setColor] = useState("rgb(243, 201, 60)");

  const navigate = useNavigate();
  const { createAccount } = useContext(AuthContext);
  const [showSidebar, setShowSidebar] = useState(window.innerWidth > 600);

  useEffect(() => {
    const handleResize = () => {
      setShowSidebar(window.innerWidth > 600);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const goToNextStage = () => {
    if (currentStage < company_stages.length) {
      setCurrentStage(currentStage + 1);
    }
  };

  const goBackStage = () => {
    if (currentStage > 1) {
      setCurrentStage(currentStage - 1);
    }
  };

  const CurrentComponent = company_stages.find(
    (stage) => stage.stage === currentStage
  )?.component;

  if (!CurrentComponent) {
    return <div>Something went wrong, please try again later.</div>;
  }

  const updateFormData = (newData) => {
    setFormData({ ...formData, ...newData });
  };

  const handleInputChange = (key, value) => {
    updateFormData({ [key]: value });
  };

  async function createNexusApp() {
    setIsCreatingApp(true);

    try {
      formData.primary_color = color;
      const directLink = await createAccount(formData);
      console.log("Direct Link generated:", directLink);

      setTimeout(() => {
        window.location.href = directLink;
        setIsCreatingApp(false);
      }, 500);
    } catch (error) {
      console.error(
        "Error during account creation and app initialization:",
        error
      );
      setIsCreatingApp(false);
      throw error;
    }
  }

  const primaryColor = colorAlphaConverter(color, 1);
  const primaryColorQuarter = colorAlphaConverter(color, 0.25);

  const componentClass = isCreatingApp
    ? `${styles.component_side_layout} ${styles.component_slide_out}`
    : styles.component_side_layout;

  const sidebarClass = isCreatingApp
    ? `${styles.authentication_content_side} ${styles.authentication_expand}`
    : styles.authentication_content_side;

  return (
    <div
      className={styles.authentication_page}
      style={{
        "--primary-color": primaryColor,
        "--primary-color-quarter": primaryColorQuarter,
      }}
    >
      <div className={styles.authentication_layout}>
        <div className={componentClass}>
          <CurrentComponent
            onNext={goToNextStage}
            onBack={goBackStage}
            handleInputChange={handleInputChange}
            formData={formData}
            userId={userId}
            color={color}
            setColor={setColor}
            createNexusApp={createNexusApp}
          />
        </div>
        {showSidebar && (
          <SignUpComponentSidebar
            className={sidebarClass}
            isCreatingApp={isCreatingApp}
            currentStage={currentStage}
            totalStages={company_stages.length}
            color={color}
            companyName={formData.company_name}
          />
        )}
      </div>
    </div>
  );
};

export default SignUpComponent;
