import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { auth } from "../services/firebase";
import { db } from "../services/firebase";
import { storage } from "../services/firebase";

import {
  query,
  orderBy,
  collection,
  doc,
  getDocs,
  getDoc,
  setDoc,
  updateDoc,
  where,
  writeBatch,
  onSnapshot,
  limit,
  addDoc,
} from "firebase/firestore";

import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";

export const PaymentContext = createContext();

export const PaymentProvider = ({ children }) => {
  const [error, setError] = useState(null);

  const DEVELOPMENT_API_KEY = "RA-q7L5m2P3nT8vY4dH0rJ6cX1zG9wA3fU";

  const fetchProducts = async () => {
    const endpoint = `https://api.development.replyassist.com/payment/fetch-stripe-products`;
    try {
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${DEVELOPMENT_API_KEY}`,
        },
      });
      return response.data;
    } catch (error) {
      setError("Failed to fetch products.");
      console.error("Error fetching products:", error);
      return [];
    }
  };

  const fetchPrices = async (productId) => {
    const endpoint = `https://api.development.replyassist.com/payment/fetch-stripe-prices?productId=${productId}`;

    try {
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${DEVELOPMENT_API_KEY}`,
        },
      });
      return response.data;
    } catch (error) {
      setError("Failed to fetch prices.");
      console.error("Error fetching prices:", error);
      return [];
    }
  };

  return (
    <PaymentContext.Provider
      value={{
        fetchProducts,
        fetchPrices,
        error,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};
