import React from "react";
import { useState, useContext, useRef, useEffect } from "react";
import styles from "../auth.module.css";

import { AuthContext } from "../../../context/AuthContext";

import ConfirmButton from "../../GlobalComponents/ConfirmButton";
import UploadIcon from "../../GlobalComponents/UploadIcon";
import UserIcon from "../../GlobalComponents/UserIcon";

const UploadProfilePicture = ({
  onNext,
  onBack,
  handleInputChange,
  color,
  formData,
}) => {
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef(null);

  const { updateAccountProfilePicture } = useContext(AuthContext);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      handleInputChange("profile_pic", imageUrl);
    }
  };

  const handleChooseFileClick = () => {
    fileInputRef.current.click();
  };
  let canContinue = true;
  let hasEnteredProfilePic = formData.profile_pic;

  async function handleCreateAccount() {
    onNext();
  }

  async function handleBackClick() {
    onBack();
  }

  return (
    <>
      <div className={styles.authentication_input_side}>
        <div className={styles.authentication_input_layout}>
          <div className={styles.input_options}>
          <p className={styles.page_title}>Create Your Nexus Account</p>
            <p className={styles.page_subtitle}>Upload a Profile Picture</p>

            <div className={styles.upload_pfp_section}>
              <div
                className={styles.uploaded_profile_pic}
                onClick={handleChooseFileClick}
              >
                {formData.profile_pic ? (
                  <img
                    src={formData.profile_pic}
                    alt="Uploaded Logo"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      borderRadius: "50%",
                    }}
                  />
                ) : (
                  <UserIcon color={color} width={25} height={25} />
                )}
              </div>
              <div className={styles.upload_text}>
                <p
                  className={styles.choose_file_title}
                  onClick={handleChooseFileClick}
                >
                  Upload a Profile Picture
                </p>
                <p className={styles.choose_file_subtitle}>Browse</p>
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleImageUpload}
                />
              </div>
            </div>
          </div>
          <>
            <ConfirmButton
              color={canContinue ? color : undefined}
              text="Continue"
              action={handleCreateAccount}
              canContinue={canContinue}
              // isLoading={isLoading}
            />
            <p className={styles.forgot_password} onClick={onBack}>
              Previous Step
            </p>
          </>
        </div>
      </div>
    </>
  );
};

export default UploadProfilePicture;
