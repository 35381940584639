import React from "react";
import { useState, useContext } from "react";

import styles from "../auth.module.css";

import { AuthContext } from "../../../context/AuthContext";
import CheckboxField from "../../GlobalComponents/CheckboxField";
import CircularLoader from "../../CircularLoader/CircularLoader";
import { useNavigate } from "react-router";
import ConfirmButton from "../../GlobalComponents/ConfirmButton";

import SignUpComponentSidebar from "../SignUpComponentSidebar";

const GeneralSignUp = ({
  onNext,
  onBack,
  handleInputChange,
  formData,
  color,
}) => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { databaseRegister, generateDirectLink } = useContext(AuthContext);

  let canContinue =
    formData.first_name &&
    formData.last_name &&
    formData.email &&
    formData.password &&
    formData.password === formData.rePassword;

  async function handleCreateAccount() {
    // generateDirectLink("vhsKDmUSLgesiLOqm31oeE4ZEIg2", "nexus");
    // databaseRegister();
    onNext();
  }

  const navigate = useNavigate();
  function handleSignIn() {
    navigate("/signin");
  }

  return (
    <>
      <div className={styles.authentication_input_side}>
        <div className={styles.authentication_input_layout}>
          <div className={styles.input_options}>
            <p className={styles.page_title}>Create Your Nexus Account</p>
            <p className={styles.page_subtitle}>Register</p>
            <div className={styles.two_column_inputs}>
              <div className={styles.half_input_object}>
                <p className={styles.login_input_titles}>First Name</p>
                <div className={styles.email_input}>
                  <input
                    onChange={(event) =>
                      handleInputChange("first_name", event.target.value)
                    }
                    value={formData.first_name}
                    className={styles.input}
                  ></input>
                </div>
              </div>
              <div className={styles.half_input_object}>
                <p className={styles.login_input_titles}>Last Name</p>
                <div className={styles.email_input}>
                  <input
                    onChange={(event) =>
                      handleInputChange("last_name", event.target.value)
                    }
                    value={formData.last_name}
                    className={styles.input}
                  ></input>
                </div>
              </div>
            </div>
            <p className={styles.login_input_titles}>Email Address</p>
            <div className={styles.email_input}>
              <input
                onChange={(event) =>
                  handleInputChange("email", event.target.value)
                }
                value={formData.email}
                className={styles.input}
              ></input>
            </div>
            <p className={styles.login_input_titles}>Password</p>
            <div className={styles.email_input}>
              <input
                type="password"
                value={formData.password}
                onChange={(event) =>
                  handleInputChange("password", event.target.value)
                }
                className={styles.input}
              ></input>
            </div>
            <p className={styles.login_input_titles}>Confirm Password</p>
            <div className={styles.email_input}>
              <input
                type="password"
                value={formData.rePassword}
                onChange={(event) =>
                  handleInputChange("rePassword", event.target.value)
                }
                className={styles.input}
              ></input>
            </div>
            {/* <div className={styles.checkbox_labels}>
              <CheckboxField
                label="I agree to the Privacy Policy"
                checked={formData.privacyPolicyChecked}
                onCheckboxChange={(checked) =>
                  handleInputChange("privacyPolicyChecked", checked)
                }
              />
              <CheckboxField
                label="I agree to the EULA"
                checked={formData.eulaChecked}
                onCheckboxChange={(checked) =>
                  handleInputChange("eulaChecked", checked)
                }
              />
            </div> */}
          </div>
          <>
            <ConfirmButton
              color={canContinue ? color : undefined}
              text="Continue"
              action={handleCreateAccount}
              canContinue={canContinue}
              // isLoading={isLoading}
            />
            <p className={styles.forgot_password} onClick={onBack}>
              Previous Step
            </p>
          </>
        </div>
      </div>
    </>
  );
};

export default GeneralSignUp;
