import React from "react";
import colorAlphaConverter from "../../utils/colorAlphaConverter";

const UserIcon = ({ width = 18, height = 18, color = "rgb(0, 0, 0)" }) => (
  <div
    style={{
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      backgroundColor: colorAlphaConverter(color, 0.05),
      boxShadow: "0px 0px 2.5px rgba(0, 0, 0, 0.3)",
    }}
  >
    <svg
      id="fi_2105556"
      enableBackground="new 0 0 128 128"
      height={height}
      width={width}
      viewBox="0 0 128 128"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m87 50c0-12.7-10.3-23-23-23s-23 10.3-23 23 10.3 23 23 23 23-10.3 23-23zm-40 0c0-9.4 7.6-17 17-17s17 7.6 17 17-7.6 17-17 17-17-7.6-17-17z"
        fill={color}
      ></path>
      <path
        d="m25.5 108.2c.5.3 1 .4 1.5.4 1 0 2.1-.5 2.6-1.5 7-12.4 20.2-20.1 34.4-20.1s27.4 7.7 34.5 20.1c.8 1.4 2.7 1.9 4.1 1.1s1.9-2.7 1.1-4.1c-8.1-14.2-23.3-23.1-39.7-23.1s-31.6 8.9-39.7 23.1c-.8 1.4-.3 3.3 1.2 4.1z"
        fill={color}
      ></path>
    </svg>
  </div>
);

export default UserIcon;
