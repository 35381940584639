import React from "react";
import styles from "./loader.module.css";

function CircularLoader({ size = "70%", color = "rgba(255, 255, 255, 1)" }) {
  // Default color updated
  return (
    <svg
      viewBox="25 25 50 50"
      className={styles.circularLoader} // Use class for SVG
      style={{ width: size }} // Keep dynamic sizing if needed
    >
      <circle
        r="20"
        cy="50"
        cx="50"
        className={styles.circularLoaderCircle} // Use class for circle
        style={{ stroke: color }} // Apply dynamic color if needed
      ></circle>
    </svg>
  );
}

export default CircularLoader;
