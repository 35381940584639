import React from "react";
import { useState, useContext, useRef, useEffect } from "react";
import styles from "../auth.module.css";

import { AuthContext } from "../../../context/AuthContext";

import ConfirmButton from "../../GlobalComponents/ConfirmButton";
import UploadIcon from "../../GlobalComponents/UploadIcon";

const LogoInformation = ({
  onNext,
  onBack,
  handleInputChange,
  color,
  formData,
}) => {
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(formData.logo);
  const fileInputRef = useRef(null);

  const { updateAccountProfilePicture } = useContext(AuthContext);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      handleInputChange("logo", imageUrl);
    }
  };

  const handleChooseFileClick = () => {
    fileInputRef.current.click();
  };

  let canContinue = formData.logo;

  async function handleCreateAccount() {
    onNext();
  }

  async function handleBackClick() {
    onBack();
  }

  return (
    <>
      <div className={styles.authentication_input_side}>
        <div className={styles.authentication_input_layout}>
          <div className={styles.input_options}>
            <p className={styles.page_title}>Brand your platform</p>
            <p className={styles.page_subtitle}>Upload Your Logo Below</p>

            <div className={styles.browse_logo_section}>
              <div className={styles.upload_text}>
                <p
                  className={styles.choose_file_title}
                  onClick={handleChooseFileClick}
                >
                  Browse Files to Upload
                </p>
                <p className={styles.choose_file_subtitle}>
                  Transparent Logo Suggested
                </p>
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleImageUpload}
                />
              </div>
              <div
                className={styles.uploaded_logo}
                onClick={handleChooseFileClick}
              >
                {formData.logo ? (
                  <img
                    src={formData.logo}
                    alt="Uploaded Logo"
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                  />
                ) : (
                  <UploadIcon />
                )}
              </div>
            </div>
          </div>
          <>
            <ConfirmButton
              color={canContinue ? color : undefined}
              text="Continue"
              action={handleCreateAccount}
              canContinue={canContinue}
              // isLoading={isLoading}
            />
            <p className={styles.forgot_password} onClick={onBack}>
              Previous Step
            </p>
          </>
        </div>
      </div>
    </>
  );
};

export default LogoInformation;
