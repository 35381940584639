import React from "react";
import { useState, useContext } from "react";
import styles from "../auth.module.css";

import { AuthContext } from "../../../context/AuthContext";

import CheckboxField from "../../GlobalComponents/CheckboxField";
import SignUpComponentSidebar from "../SignUpComponentSidebar";
import CustomDropdown from "../CustomDropdown";
import DownArrowIcon from "../../GlobalComponents/DownArrowIcon";
import ConfirmButton from "../../GlobalComponents/ConfirmButton";

const GeneralInformation = ({
  onNext,
  onBack,
  handleInputChange,
  formData,
  createNexusApp,
  color,
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const [error, setError] = useState(null);

  let canContinue = true;

  async function handleCreateAccount() {
    createNexusApp();
  }

  async function handleBackClick() {
    onBack();
  }

  const toggleDropdown = () => setIsDropdownVisible(!isDropdownVisible);

  const genderOptions = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];

  return (
    <>
      <div className={styles.authentication_input_side}>
        <div className={styles.authentication_input_layout}>
          <div className={styles.input_options}>
            <p className={styles.page_title}>Create Your Nexus Account</p>
            <p className={styles.page_subtitle}>Tell us a bit about yourself</p>

            <p className={styles.login_input_titles}>
              Add a bio to your profile (Optional)
            </p>
            <div className={styles.paragraph_input}>
              <textarea
                onChange={(event) =>
                  handleInputChange("biography", event.target.value)
                }
                value={formData.biography}
                className={styles.textarea_input}
              ></textarea>
            </div>

            {/* <CheckboxField
              label="I am a financial advisor/financial services firm"
              checked={formData.isFinancialAdvisor}
              onCheckboxChange={(checked) =>
                handleInputChange("isFinancialAdvisor", checked)
              }
            /> */}
          </div>

          <>
            <ConfirmButton
              color={canContinue ? color : undefined}
              text="Complete"
              action={handleCreateAccount}
              canContinue={canContinue}
              // isLoading={isLoading}
            />
            <p className={styles.forgot_password} onClick={onBack}>
              Previous Step
            </p>
          </>
        </div>
      </div>
    </>
  );
};

export default GeneralInformation;
