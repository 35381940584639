import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { auth } from "../services/firebase";
import { db } from "../services/firebase";
import { storage } from "../services/firebase";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import {
  query,
  orderBy,
  collection,
  doc,
  getDocs,
  getDoc,
  setDoc,
  updateDoc,
  where,
  writeBatch,
  onSnapshot,
  limit,
  addDoc,
} from "firebase/firestore";
import { toast } from "react-toastify";

import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [error, setError] = useState(null);

  const DEVELOPMENT_API_KEY = "RA-q7L5m2P3nT8vY4dH0rJ6cX1zG9wA3fU";

  // const signIn = (email, password) => {
  //   return signInWithEmailAndPassword(auth, email, password)
  //     .then((credential) => {

  //       localStorage.setItem("authUser", JSON.stringify(credential.user));
  //       fetchUserData(credential.user.uid);

  //       return Promise.all([
  //         fetchUserProspects(credential.user.uid),
  //         fetchUserFollowUps(credential.user.uid),
  //         fetchUserConversationsAndMessages(credential.user.uid),
  //       ])
  //         .then(() => {
  //           return credential;
  //         })
  //         .catch((err) => {
  //           console.error("Error fetching data:", err);
  //           throw err;
  //         });
  //     })
  //     .catch((error) => {
  //       setError(error.message);
  //       throw error;
  //     });
  // };

  // async function createAccount(formData) {
  //   const domainName = formData.app_name;
  //   const singleDocId = "default";
  //   const docRef = doc(db, domainName, singleDocId);

  //   try {
  //     const userCredential = await createUserWithEmailAndPassword(
  //       auth,
  //       formData.email,
  //       formData.password
  //     );
  //     console.log("Account created successfully:", userCredential.user);

  //     const userDocRef = doc(docRef, "Accounts", userCredential.user.uid);
  //     await setDoc(
  //       userDocRef,
  //       {
  //         uid: userCredential.user.uid,
  //         email: formData.email,
  //         firstName: formData.firstName,
  //         lastName: formData.lastName,
  //         appName: domainName,
  //       },
  //       { merge: true }
  //     );

  //     const directLink = await generateDirectLink(
  //       userCredential.user.uid,
  //       domainName
  //     );

  //     return directLink;
  //   } catch (error) {
  //     console.error("Error in account creation and updating process:", error);
  //     throw error;
  //   }
  // }

  async function createAccount(formData) {
    let id;

    console.log(formData);
    try {
      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          formData.email,
          formData.password
        );
        id = userCredential.user.uid;
        console.log("Account created successfully:", userCredential.user);
      } catch (error) {
        if (error.code === "auth/email-already-in-use") {
          toast.success("Email Already In Use!");
          console.log("EMAIL IN USE");
        } else {
          throw error;
        }
      }
      const domainName = formData.app_name;
      const docRef = doc(db, "Accounts", domainName);

      const logoImageRef = storageRef(storage, `nexus_app_logos/${domainName}`);
      const response = await fetch(formData.logo);
      const blob = await response.blob();
      await uploadBytes(logoImageRef, blob);
      const logoImageUrl = await getDownloadURL(logoImageRef);

      await setDoc(
        docRef,
        {
          account_holder: id,
          domain_name: domainName,
          company_name: formData.company_name,
          logo_image: logoImageUrl,
          primary_color: formData.primary_color,
        },
        { merge: true }
      );
      const userDocRef = doc(docRef, "Accounts", id);
      await setDoc(
        userDocRef,
        {
          id: id,
          email: formData.email,
          first_name: formData.first_name,
          last_name: formData.last_name,
          admin: true,
        },
        { merge: true }
      );

      const directLink = await generateDirectLink(id, domainName);

      return directLink;
    } catch (error) {
      console.error("Error in account creation and updating process:", error);
      throw error;
    }
  }

  // async function createAccount(formData) {
  //   let uid;

  //   console.log(formData);
  //   try {
  //     try {
  //       const userCredential = await createUserWithEmailAndPassword(
  //         auth,
  //         formData.email,
  //         formData.password
  //       );
  //       uid = userCredential.user.uid;
  //       console.log("Account created successfully:", userCredential.user);
  //     } catch (error) {
  //       if (error.code === "auth/email-already-in-use") {
  //         const accountsQuery = query(
  //           collection(db, "Accounts"),
  //           where("email", "==", formData.email)
  //         );
  //         const querySnapshot = await getDocs(accountsQuery);

  //         if (!querySnapshot.empty) {
  //           const userDoc = querySnapshot.docs[0];
  //           uid = userDoc.data().id;
  //           console.log("Existing user UID fetched:", uid);
  //         } else {
  //           throw new Error(
  //             "Email is in use, but no corresponding Firestore document found."
  //           );
  //         }
  //       } else {
  //         throw error;
  //       }
  //     }
  //     const domainName = formData.app_name;
  //     const docRef = doc(db, domainName, "default");

  //     // Image upload to Firebase Storage
  //     const logoImageRef = storageRef(storage, `nexus_app_logos/${domainName}`);
  //     const response = await fetch(formData.logo);
  //     const blob = await response.blob();
  //     await uploadBytes(logoImageRef, blob);
  //     const logoImageUrl = await getDownloadURL(logoImageRef);

  //     // Firestore operations
  //     const domainRef = doc(db, domainName, "default");
  //     await setDoc(
  //       domainRef,
  //       {
  //         account_holder: uid,
  //         domain_name: domainName,
  //         company_name: formData.company_name,
  //         logo_image: logoImageUrl,
  //         primary_color: formData.primary_color,
  //       },
  //       { merge: true }
  //     );
  //     const userDocRef = doc(docRef, "Accounts", uid);
  //     await setDoc(
  //       userDocRef,
  //       {
  //         uid: uid,
  //         email: formData.email,
  //         first_name: formData.first_name,
  //         last_name: formData.last_name,
  //         admin: true,
  //       },
  //       { merge: true }
  //     );

  //     const directLink = await generateDirectLink(uid, domainName);

  //     return directLink;
  //   } catch (error) {
  //     console.error("Error in account creation and updating process:", error);
  //     throw error;
  //   }
  // }

  async function generateDirectLink(userId, domain) {
    const request = {
      userId: userId,
      domain: domain,
    };

    try {
      const response = await axios.post(
        "https://nexus-84ef5.uc.r.appspot.com/create-direct-link",
        request
      );
      console.log(
        "Response from direct link generation:",
        response.data.magicLink
      );
      return response.data.magicLink;
    } catch (error) {
      console.error("Error generating direct link:", error);
      throw error;
    }
  }

  const databaseRegister = async (email, firstName, lastName) => {
    try {
      const accountsRef = collection(db, "RegisteredUsers");
      const existingUsersQuery = query(
        accountsRef,
        where("email", "==", email)
      );
      const querySnapshot = await getDocs(existingUsersQuery);

      let userDocRef;

      if (!querySnapshot.empty) {
        console.log("An account with this email already exists.");

        userDocRef = querySnapshot.docs[0].ref;
      } else {
        userDocRef = doc(accountsRef);

        await setDoc(userDocRef, {
          uid: userDocRef.id,
          firstName: firstName,
          lastName: lastName,
          email: email,
          timestamp: Date.now(),
        });

        console.log(
          "User data added successfully to RegisteredUsers collection.",
          userDocRef.id
        );
      }

      const stripeCustomer = await createOrFetchStripeCustomer(
        email,
        firstName,
        lastName
      );
      if (stripeCustomer && stripeCustomer.customerId) {
        console.log("Stripe customer created or fetched successfully.");

        await updateDoc(userDocRef, {
          stripe_id: stripeCustomer.customerId,
        });

        console.log("Updated user document with Stripe customer ID.");
      }

      return {
        success: true,
        uid: userDocRef.id,
        stripe_id: stripeCustomer?.customerId || null,
      };
    } catch (error) {
      console.error(
        "Error handling user registration and Stripe customer creation:",
        error
      );
      throw error;
    }
  };

  const createOrFetchStripeCustomer = async (email, firstName, lastName) => {
    try {
      const endpoint =
        "https://api.development.replyassist.com/payment/create-or-fetch-customer";

      const response = await axios.post(
        endpoint,
        {
          email,
          firstName,
          lastName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${DEVELOPMENT_API_KEY}`,
          },
        }
      );

      console.log("Customer data:", response.data);

      setError(null);
      return response.data;
    } catch (error) {
      console.error(
        "Error creating or fetching customer:",
        error.response?.data?.message || error.message
      );
      setError(
        error.response?.data?.message ||
          "An error occurred while processing customer data."
      );
      return null;
    }
  };

  const checkAppNameExists = async (appName) => {
    try {
      const appQuery = query(
        collection(db, "Accounts"),
        where("company_domain", "==", appName)
      );
      const querySnapshot = await getDocs(appQuery);
      return !querySnapshot.empty;
    } catch (error) {
      console.error("Error checking app name existence:", error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        // signIn,
        createAccount,
        databaseRegister,
        generateDirectLink,
        checkAppNameExists,
        error,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
