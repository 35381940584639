import React, { useEffect, useState } from "react";
import styles from "./auth.module.css";

const SignUpComponentSidebar = ({
  currentStage,
  totalStages,
  color,
  className,
  isCreatingApp,
  companyName,
}) => {
  const [displayPercentage, setDisplayPercentage] = useState(0);

  useEffect(() => {
    if (!isCreatingApp) {
      const newPercentage = Math.round((currentStage / totalStages) * 100);
      setDisplayPercentage(newPercentage);
    }
  }, [currentStage, totalStages, isCreatingApp]);

  const radius = 145;
  const circumference = 2 * Math.PI * radius;
  let offset = ((100 - displayPercentage) / 100) * circumference;

  if (isCreatingApp) {
    offset = 0;
  }

  return (
    <div className={className} style={{ position: "relative" }}>
      <svg
        width="300"
        height="300"
        style={{ border: `2px solid ${color}` }}
        className={`${styles.authentication_content_side_circle} ${
          isCreatingApp ? styles.loadingCircle : ""
        }`}
      >
        <circle
          cx="150"
          cy="150"
          r={radius}
          fill="none"
          stroke={color}
          strokeWidth="4"
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={isCreatingApp ? "0" : offset}
          transform="rotate(-90 150 150)"
          className={`${isCreatingApp ? styles.loadingPath : ""} ${
            styles.progressCirclePath
          }`}
        />
      </svg>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          color: color,
        }}
      >
        {isCreatingApp ? (
          <>
            <span style={{ fontSize: "20px", fontWeight: "100" }}>
              Creating {companyName}
            </span>
          </>
        ) : (
          <span style={{ fontSize: "50px", fontWeight: "100" }}>
            {displayPercentage}%
          </span>
        )}
      </div>
    </div>
  );
};

export default SignUpComponentSidebar;
