export default function colorAlphaConverter(rgb, alpha) {
  const regex = /rgb\((\d+),\s*(\d+),\s*(\d+)\)/;

  const match = rgb.match(regex);

  if (match) {
    const [, red, green, blue] = match;

    return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
  } else {
    console.error("Invalid RGB format:", rgb);
    return rgb;
  }
}
