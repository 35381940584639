import React from "react";
import { useParams } from "react-router-dom";
import SignUpComponent from "../components/AuthComponents/SignUpComponent";

const SignUpPage = () => {
  const { stepNum } = useParams();
  return (
    <div>
       <SignUpComponent step={parseInt(stepNum)} />
    </div>
  );
};

export default SignUpPage;
