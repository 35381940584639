import React from "react";

const UploadIcon = ({
  width = 30,
  height = 30,
  color = "rgba(0, 0, 0, 0.5)",
  strokeWidth = 1,
  ...props
}) => (
  <svg
    id="fi_13434902"
    enable-background="new 0 0 512 512"
    width={width}
    height={height}
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="m395.29 270.85c5.87-1.38 8.16-8.52 4.18-13.05l-78.29-89.3c-6.08-6.93-14.85-10.91-24.06-10.91-.01 0-.01 0-.02 0-9.22.01-18 3.99-24.07 10.94l-106.28 121.56-49.28-56.21c-6.08-6.93-14.85-10.91-24.06-10.91-.01 0-.01 0-.02 0-9.23.01-18 3.99-24.07 10.94l-53.32 60.98v-192.89c0-23.2 18.8-42 42-42h332c23.2 0 42 18.8 42 42v159.81c0 3.84 2.76 7.13 6.55 7.79l.16.03c4.85.85 9.29-2.89 9.29-7.81v-159.82c0-32.03-25.97-58-58-58h-332c-32.03 0-58 25.97-58 58v224c0 32.03 25.97 58 58 58h246.08c4.36 0 7.92-3.56 7.92-7.92v-.08c0-51.05 35.61-93.95 83.29-105.15z"
        fill={color}
      ></path>
      <ellipse
        cx="164.77"
        cy="132.13"
        rx="39.35"
        ry="39.35"
        transform="matrix(.973 -.23 .23 .973 -25.949 41.392)"
        fill={color}
      ></ellipse>
      <path
        d="m420 284c-50.73 0-92 41.27-92 92s41.27 92 92 92 92-41.27 92-92-41.27-92-92-92zm-28.28 77.34 21.39-21.4c1.39-2.36 3.95-3.95 6.89-3.95s5.5 1.59 6.89 3.95l21.39 21.4c3.12 3.12 3.12 8.19 0 11.31-3.13 3.12-8.19 3.12-11.31 0l-8.97-8.97v44.32c0 4.42-3.58 8-8 8s-8-3.58-8-8v-44.31l-8.97 8.97c-3.12 3.12-8.19 3.12-11.31 0-3.13-3.13-3.13-8.19 0-11.32z"
        fill={color}
      ></path>
    </g>
  </svg>
);

export default UploadIcon;
