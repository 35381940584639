import React from "react";
import { useState, useContext } from "react";
import styles from "../auth.module.css";

import ConfirmButton from "../../GlobalComponents/ConfirmButton";
import { AuthContext } from "../../../context/AuthContext";

const PlatformInformation = ({
  onNext,
  onBack,
  handleInputChange,
  formData,
  color,
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const [error, setError] = useState(null);

  const [appNameExists, setAppNameExists] = useState(null);

  const { checkAppNameExists } = useContext(AuthContext);

  let canContinue =
    formData.app_name && formData.company_name && !appNameExists;

  async function handleCreateAccount() {
    onNext();
  }

  async function handleBackClick() {
    onBack();
  }

  const toggleDropdown = () => setIsDropdownVisible(!isDropdownVisible);

  // Modified handleInputChange to filter out spaces for specific fields
  const modifiedHandleInputChange = (field, value) => {
    // Remove spaces for app_name and company_website fields
    if (field === "app_name" || field === "company_website") {
      value = value.replace(/\s+/g, "");
    }
    handleInputChange(field, value);
  };

  const handleAppNameChange = async (event) => {
    const appName = event.target.value.toLowerCase().replace(/\s+/g, ""); // Remove spaces
    modifiedHandleInputChange("app_name", appName); // Use modified function
    const exists = await checkAppNameExists(appName);
    setAppNameExists(exists);
  };

  return (
    <>
      <div className={styles.authentication_input_side}>
        <div className={styles.authentication_input_layout}>
          <div className={styles.input_options}>
            <p className={styles.page_title}>Create Your Nexus App</p>
            <p className={styles.page_subtitle}>Tell Us About Your Platform</p>

            <p className={styles.login_input_titles}>Your Platform Name</p>
            <div className={styles.email_input}>
              <input
                onChange={(event) =>
                  modifiedHandleInputChange("company_name", event.target.value)
                }
                value={formData.company_name}
                className={styles.input}
              ></input>
            </div>
            <p className={styles.login_input_titles}>
              Company Website (Optional)
            </p>
            <div className={styles.email_input}>
              <input
                onChange={(event) =>
                  modifiedHandleInputChange(
                    "company_website",
                    event.target.value.toLowerCase()
                  )
                }
                value={formData.company_website}
                className={styles.input}
              ></input>
            </div>
            <p className={styles.login_input_titles}>App Name</p>
            <div className={styles.email_input}>
              <input
                onChange={handleAppNameChange}
                value={formData.app_name}
                className={styles.input}
              ></input>
            </div>
            {formData?.app_name ? (
              // Display example domains if nexus_name is provided
              <div className={styles.example_domain_options}>
                <p className={styles.example_domain}>
                  https://portal.mynexusapp.com/{formData.app_name}
                </p>
                <p className={styles.example_domain}>
                  https://portal.{formData.app_name}.com
                </p>
              </div>
            ) : (
              // Display an itinerary or other content if nexus_name is not provided
              <div className={styles.example_domain_options}>
                <p className={styles.example_domain_example}>
                  https://portal.mynexusapp.com/example
                </p>
                <p className={styles.example_domain_example}>
                  https://portal.example.com
                </p>
              </div>
            )}
          </div>
          {appNameExists ? (
            <p className={styles.error_text}>App Already Exists </p>
          ) : (
            <p></p>
          )}
          <>
            <ConfirmButton
              color={canContinue ? color : undefined}
              text="Continue"
              action={handleCreateAccount}
              canContinue={canContinue}
              // isLoading={isLoading}
            />

            <p className={styles.forgot_password}>Having Trouble? Contact Us</p>
          </>
        </div>
      </div>
    </>
  );
};

export default PlatformInformation;
